body {
  font-family: Roboto, sans-serif;
  img {
    width: 100%;
  }
  footer {
    border-top: solid 1px;
    margin-top: 10px;
  }
}
